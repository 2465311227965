import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { connect } from "react-redux";
import Table from "react-bootstrap/Table";
import { delRoles, getRoles, setRoles } from "../../services/user.service";

const mapStateToProps = ({ auth }) => ({ auth });
const Role = ({ history, auth, user }) => {
  const form = useRef();
  const checkBtn = useRef();

  const [, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const [roleList, setRoleList] = useState([]);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  React.useEffect(() => {
    if (isLoggedIn) {
    }
  }, [history, isLoggedIn]);
  const fetchRoleItems = () => {
    getRoles()
      .then((res) => {
        setRoleList(res?.data?.data || []);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  React.useEffect(() => {
    fetchRoleItems();
  }, []);
  const deleteRoleItem = (item) => {
    delRoles(item)
      .then((res) => {
        fetchRoleItems();
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!title && !value) {
      alert("Please enter required fields");
    }
    setLoading(true);
    setRoles({ title: title, value: value })
      .then((res) => {
        fetchRoleItems();
        setTitle("");
        setValue("");
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
    form.current.validateAll();
  };

  return (
    <div className="d-flex align-items-center justify-content-center mt-5">
      <div className="">
        <Form onSubmit={handleSubmit} ref={form}>
          <div className="row">
            <div className="form-group col-md-4">
              <label htmlFor="title">
                Title <span className="text-danger">*</span>
              </label>
              <div className="position-relative input-wrap">
                <Input
                  type="text"
                  className="form-control"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="value">
                Alias <span className="text-danger">*</span>
              </label>
              <div className="position-relative input-wrap">
                <Input
                  type="value"
                  className="form-control"
                  name="value"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-md-2 my-6 py-2">
              <button className="btn btn-primary btn-block my-4">
                {/* {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )} */}
                <span>Save</span>
              </button>
            </div>
          </div>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        <Table striped bordered hover className="my-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Alias</th>
            </tr>
          </thead>
          <tbody>
            {roleList?.map((item, index) => (
              <tr key={index}>
                <td>{item?.id}</td>
                <td>{item?.title}</td>
                <td>{item?.value}</td>
                <td style={{ display: "flex" }}>
                  <button
                    className="btn btn-primary btn-block"
                    // disabled={loading}
                    onClick={() => {
                      setTitle(item?.title);
                      setValue(item?.value);
                    }}
                  >
                    {/* {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )} */}
                    <span>Edit</span>
                  </button>
                  <button
                    className="btn btn-danger btn-block mx-4"
                    onClick={() => {
                      deleteRoleItem(item);
                    }}
                  >
                    <span>Delete</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Role);
