import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { connect } from "react-redux";
import {
  getMenus,
  getRoles,
  inviteUser,
  setPermissionMatrix,
  updateUser,
  getUser,
  getRoleMatrix,
} from "../../services/user.service";
import { withRouter } from "react-router-dom";
import { addExistPermissionToMenuList } from "../../utils/permissionUtils";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const mapStateToProps = ({ auth }) => ({ auth });

const UserPage = ({ history, dispatch, auth, match }) => {
  const [user, setUser] = React.useState([]);
  const form = useRef();
  const checkBtn = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [menuList, setMenuList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [roleMatrix, setRoleMatrix] = useState([]);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const [finalMenuList, setFinalMenuList] = React.useState([]);
  const selectedRoleObj = roleList.find((item) => item.value === selectedRole);

  const fetchItems = () => {
    getRoles()
      .then((res) => {
        setRoleList(res?.data?.data);
        // setSelectedRole(res?.data?.data[0].value);
      })
      .catch((e) => {
        console.log(e);
      });
    getMenus()
      .then((res) => {
        setMenuList(res?.data);
      })
      .catch((e) => {});
    getRoleMatrix()
      .then((res) => {
        setRoleMatrix(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  React.useEffect(() => {
    if (match?.params?.id) {
      getUser(match?.params?.id)
        .then((res) => {
          setUser(res?.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isLoggedIn, match]);

  React.useEffect(() => {
    if (user) {
      setEmail(user?.email);
      setName(user?.name);
      setPhone(user?.phone);
      setSelectedRole(user?.user_type);
      fetchItems();
      handleRoleOnChange(user?.user_type);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const updatePermission = (user_id) => {
    if (menuList?.length > 0) {
      setPermissionMatrix({ user: user_id, menu: menuList })
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const addUser = () => {
    const admin = JSON.parse(localStorage.getItem("user"));
    let data = {
      name: name,
      email: email,
      phone: phone,
      company_id: admin?.user?.company?.id,
      company: admin?.user?.company?.name || "",
      user_type: selectedRole,
      user_flag: admin?.user?.user_flag,
    };

    if (user?.id) {
      data.id = user?.id;
      updateUser(data)
        .then((res) => {
          const user_id = user?.id;
          updatePermission(user_id);
          // alert("user updated");
        })
        .catch((e) => {
          const user_id = e?.response?.data?.reference_id;
          updatePermission(user_id);
        });
    } else {
      inviteUser(data)
        .then((res) => {
          const user_id = res?.data?.id;
          updatePermission(user_id);
          // alert("user invited");
        })
        .catch((e) => {
          const user_id = e?.response?.data?.reference_id;
          updatePermission(user_id);
        });
    }
  };
  const handleRoleOnChange = (role_value) => {
    const role_id = roleList?.find((item) => item.value === role_value)?.value;
    // console.log("roleList", roleList);
    // console.log("roleMatrix", roleMatrix);

    const a = menuList?.map((item, index) => {
      return item?.menu_items?.map((menu_item) => {
        let role_object = roleMatrix.find((value) => value.role === role_id);
        const menu_ids = role_object?.menu_id?.split(",");
        if (menu_ids && menu_ids.includes(String(menu_item?.id))) {
          menu_item.default = true;
          menu_item.checked = true;
        } else {
          menu_item.default = false;
          menu_item.checked = false;
        }
      });
    });
    console.log(menuList);
    // setMenuList([...menuList]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    form.current.validateAll();
  };

  React.useEffect(() => {
    setFinalMenuList(addExistPermissionToMenuList(menuList, selectedRoleObj));
  }, [menuList, selectedRole, selectedRoleObj]);

  return (
    <div className="container mt-5">
      <Form onSubmit={handleSubmit} ref={form}>
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="name">
              Name <span className="text-danger">*</span>
            </label>
            <div className="position-relative input-wrap">
              <Input
                type="text"
                className="form-control"
                name="name"
                value={name}
                onChange={(e) => setName(e?.target?.value)}
                validations={[required]}
              />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="email">
              Email <span className="text-danger">*</span>
            </label>
            <div className="position-relative input-wrap">
              <Input
                type="email"
                className="form-control"
                name="email"
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
                validations={[required]}
              />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="email">
              Phone <span className="text-danger">*</span>
            </label>
            <div className="position-relative input-wrap">
              <Input
                type="phone"
                className="form-control"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e?.target?.value)}
                validations={[required]}
              />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="role">
              Select Team <span className="text-danger">*</span>
            </label>
            <div className="position-relative input-wrap">
              <Select
                className="form-control"
                name="role"
                value={selectedRole}
                validations={[required]}
                onChange={(e) => {
                  setSelectedRole(e?.target?.value);
                  handleRoleOnChange(e?.target?.value);
                }}
              >
                {roleList?.map((item) => (
                  <option key={"role" + item?.id} value={item?.value}>
                    {item?.title}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          {true &&
            finalMenuList?.map((item, index) => (
              <div key={"service" + index} className="col-sm-3">
                <h4>{item?.service + ""}</h4>
                {item?.menu_items?.map((menu_item) => (
                  <div key={"menu" + menu_item?.id} className="checkbox-row">
                    <Input
                      name={menu_item?.value}
                      value={menu_item?.value}
                      type="checkbox"
                      style={{
                        height: "24px",
                        width: "24px",
                      }}
                      checked={!!menu_item?.checked}
                      disabled={!!menu_item?.checked}
                      onChange={(e) => {
                        menu_item.checked = e.target.checked;
                      }}
                    />{" "}
                    <div style={{ fontSize: "18px" }}>{menu_item?.title}</div>
                  </div>
                ))}
              </div>
            ))}
        </div>

        <div className="form-group py-4">
          <button
            className="btn btn-primary btn-block"
            onClick={() => addUser()}
          >
            <span>Send Invite</span>
          </button>
        </div>

        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}
        <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </Form>
    </div>
  );
};

export default connect(mapStateToProps)(withRouter(UserPage));
