import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  PROFILE_UPDATE_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  PASSWORD_RESET,
  PASSWORD_RESET_FAIL,
} from "./types";

import AuthService from "../services/auth.service";

export const register =
  (
    username,
    email,
    password,
    company,
    phone,
    userType,
    userFlag,
    country,
    companyWebsite,
    linkedinProfile,
    designation,
    businessType
  ) =>
  (dispatch) => {
    return AuthService.register(
      username,
      email,
      password,
      company,
      phone,
      userType,
      userFlag,
      country,
      companyWebsite,
      linkedinProfile,
      designation,
      businessType
    ).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const profileUpdate =
  ({ username, company, phone, files }) =>
  (dispatch) => {
    return AuthService.profileUpdate(username, company, phone, files).then(
      ({ data }) => {
        console.log(data);
        const userData = JSON.parse(localStorage.getItem("user"));
        userData.user.name = username;
        userData.user.phone = phone;
        userData.user.user_picture = data?.user_picture;
        userData.user.company.name = company;

        localStorage.setItem("user", JSON.stringify(userData));
        dispatch({
          type: PROFILE_UPDATE_SUCCESS,
          payload: { user: userData },
        });

        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      if (data.status >= 400) {
        const message =
          (data && data.data && data.data.detail) || data.data.detail;
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        error?.response?.data?.detail || error.message || error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const passwordreset = (password) => (dispatch) => {
  return AuthService.passwordreset(password).then(
    (data) => {
      dispatch({
        type: PASSWORD_RESET,
        payload: { password: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: PASSWORD_RESET_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
