import React from "react";
import { Link, useLocation } from "react-router-dom";

const UserManagementLayout = ({ children }) => {
  const location = useLocation();

  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light position-sticky"
        style={{ top: 0, zIndex: 9999 }}
      >
        <div className="container">
          <Link to="" className="navbar-brand">
            <img style={{ height: 30 }} src="logo-w.png" alt="logo" />
          </Link>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  to="/profile"
                  className={`nav-link ${
                    location.pathname === "/profile" && "active"
                  }`}
                >
                  Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/user-management"
                  className={`nav-link ${
                    location.pathname === "/user-management" && "active"
                  }`}
                >
                  All Users
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/user-management/user"
                  className={`nav-link ${
                    location.pathname.includes("/user-management/user") &&
                    "active"
                  }`}
                >
                  User
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/user-management/menu"
                  className={`nav-link ${
                    location.pathname === "/user-management/menu" && "active"
                  }`}
                >
                  Menu
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/user-management/role"
                  className={`nav-link ${
                    location.pathname === "/user-management/role" && "active"
                  }`}
                >
                  Role
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/user-management/permission"
                  className={`nav-link ${
                    location.pathname === "/user-management/permission" &&
                    "active"
                  }`}
                >
                  Permission
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {children}
    </div>
  );
};

export default UserManagementLayout;
