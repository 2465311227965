/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { baseurl } from "../utils/const";

// import { Router, Switch, Route, Link } from "react-router-dom";

const mapStateToProps = ({ auth }) => ({ auth });

const Profile2 = ({ dispatch, auth }) => {
  // const [currentUser, setCurrentUser] = React.useState({});
  // React.useEffect(() => {
  //   if (auth?.user) {
  //     setCurrentUser(auth?.user);
  //   }
  // }, [auth]);
  const currentUser = auth?.user;
  if (!currentUser) {
    return <Redirect to={baseurl + "/login"} />;
  }
  function redirectToService(serviceName) {
    if (serviceName === "console") {
      window.location.replace(
        // process.env.REACT_APP_CONSOLE_BASE_URL +
        //   "?token=" +
        //   btoa(JSON.stringify(currentUser))
        process.env.REACT_APP_CONSOLE_BASE_URL + "?token=" + currentUser?.access
      );
      // console.log(
      //   process.env.REACT_APP_CONSOLE_BASE_URL + "?token=" + currentUser?.access
      // );
    }
    if (serviceName === "shop") {
      window.location.replace(
        process.env.REACT_APP_SHOP_BASE_URL +
          "user/login-from-sso" +
          "?token=" +
          btoa(JSON.stringify(currentUser))
      );
    }
    if (serviceName === "merchantbay") {
      window.location.replace(
        process.env.REACT_APP_MERCHANTBAY_BASE_URL +
          "user/login-from-sso" +
          "?token=" +
          btoa(JSON.stringify(currentUser))
      );
    }
    if (serviceName === "omd") {
      // window.location.replace(
      //   process.env.REACT_APP_OMD_BASE_URL +
      //     "?token=" +
      //     btoa(JSON.stringify(currentUser))
      // );
      window.location.replace(
        // process.env.REACT_APP_CONSOLE_BASE_URL +
        //   "?token=" +
        //   btoa(JSON.stringify(currentUser))
        process.env.REACT_APP_CONSOLE_BASE_URL + "?token=" + currentUser?.access
      );
    }
    if (serviceName === "tools") {
      window.location.replace(
        process.env.REACT_APP_TOOLS_BASE_URL +
          "?token=" +
          btoa(JSON.stringify(currentUser))
      );
    }
    if (serviceName === "store") {
      window.location.replace(
        process.env.REACT_APP_STORE_BASE_URL +
          "?token=" +
          btoa(JSON.stringify(currentUser))
      );
    }
  }

  return (
    <div className="sso-contentCol col-md-9 col-md-8 col-sm-12">
      <div className="sso-contentcol-inner m-auto">
        <div className="productWrap pb-100">
          <h2 className="text-center fs-35 pb-50 m-0">Your Subscriptions</h2>
          <div className="row">
            <div
              className="product-itemWrap col-md-6 col-sm-12"
              style={{ cursor: "pointer" }}
              onClick={() => redirectToService("merchantbay")}
            >
              <div className="titleWrap pb-50">
                <div className="titleBox brd-shadow b-radius position-relative text-center">
                  <h3 className="fs-30">Shop</h3>
                  <h5 className="fs-15">Business to Business</h5>
                  <div className="service position-absolute">Free</div>
                  <span className="ball-bg position-absolute"></span>
                </div>
              </div>
              <div className="itemWrap">
                <ul>
                  <li>
                    <span>Buy & Sell</span>
                  </li>
                  <li>
                    <span>Connect</span>
                  </li>
                  <li>
                    <span>RFQ</span>
                  </li>
                  <li>
                    <span>Search</span>
                  </li>
                  <li>
                    <span>Insights</span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="product-itemWrap col-md-6 col-sm-12 float-end"
              style={{ cursor: "pointer" }}
              onClick={() => redirectToService("merchantbay")}
            >
              <div className="titleWrap pb-50">
                <div className="titleBox brd-shadow b-radius position-relative text-center">
                  <h3 className="fs-30">Manufacturers</h3>
                  <h5 className="fs-15">Business to Business</h5>
                  <div className="service position-absolute">Free</div>
                  <span className="ball-bg position-absolute"></span>
                </div>
              </div>
              <div className="itemWrap">
                <ul>
                  <li>
                    <span>Digital Profile</span>
                  </li>
                  <li>
                    <span>Global Visibility</span>
                  </li>
                  <li>
                    <span>Instant Buyer Connection</span>
                  </li>
                  <li>
                    <span>RFQ Update</span>
                  </li>
                  <li>
                    <span>Industry Insights</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="productWrap">
          <h2 className="text-center fs-35 pb-50 m-0">
            More from Merchant Bay
          </h2>
          <div className="row">
            <div
              className="product-itemWrap col-md-6 col-sm-12"
              style={{ cursor: "pointer" }}
              onClick={() => redirectToService("omd")}
            >
              <div className="titleWrap pb-50">
                <div className="titleBox brd-shadow b-radius position-relative text-center">
                  <h3 className="fs-30">Smart OMD</h3>
                  <h5 className="fs-15">Macro Management Simplified</h5>
                  <div className="service position-absolute premium">
                    <a href="#"> Premium </a>
                  </div>
                  <span className="ball-bg position-absolute"></span>
                </div>
              </div>
              <div className="itemWrap">
                <ul>
                  <li>
                    <span>T&A Management</span>
                  </li>
                  <li>
                    <span>Production Update</span>
                  </li>
                  <li>
                    <span>QC Update</span>
                  </li>
                  <li>
                    <span>Instant Messaging</span>
                  </li>
                  <li>
                    <span>Team Collaboration</span>
                  </li>
                  <li>
                    <span>Transparency in Operation</span>{" "}
                  </li>
                </ul>
                <div className="text-center pt-3">
                  <button className="btn-request btn btn-secondary">
                    Request a Demo
                  </button>
                </div>
              </div>
            </div>
            <div
              className="product-itemWrap col-md-6 col-sm-12 float-end"
              onClick={() => redirectToService("console")}
              style={{ cursor: "pointer" }}
            >
              <div className="titleWrap pb-50">
                <div className="titleBox brd-shadow b-radius position-relative text-center">
                  <h3 className="fs-30">M-Factory</h3>
                  <h5 className="fs-15">Micro Management Simplified</h5>
                  <div className="service position-absolute premium">
                    <a href="#"> Premium </a>
                  </div>
                  <span className="ball-bg position-absolute"></span>
                </div>
              </div>
              <div className="itemWrap pb-50">
                <ul>
                  <li>
                    <span>Production Intelligenece</span>
                  </li>
                  <li>
                    <span>Store Management System</span>
                  </li>
                  <li>
                    <span>Commercial Monitoring</span>
                  </li>
                  <li>
                    <span>Planning Board</span>
                  </li>
                  <li>
                    <span>
                      Transparency & Traceability <br /> in Micro Management
                      System
                    </span>
                  </li>
                </ul>
                <div className="text-center pt-3">
                  <button className="btn-request btn btn-secondary">
                    Request a Demo
                  </button>
                </div>
              </div>
            </div>
            <div
              className="product-itemWrap col-md-6 col-sm-12 float-end"
              onClick={() => redirectToService("store")}
              style={{ cursor: "pointer" }}
            >
              <div className="titleWrap pb-50">
                <div className="titleBox brd-shadow b-radius position-relative text-center">
                  <h3 className="fs-30">M-Store</h3>
                  <h5 className="fs-15">Micro Management Simplified</h5>
                  <div className="service position-absolute premium">
                    <a href="#"> Premium </a>
                  </div>
                  <span className="ball-bg position-absolute"></span>
                </div>
              </div>
              <div className="itemWrap pb-50">
                <ul>
                  <li>
                    <span>Production Intelligenece</span>
                  </li>
                  <li>
                    <span>Store Management System</span>
                  </li>
                  <li>
                    <span>Commercial Monitoring</span>
                  </li>
                  <li>
                    <span>Planning Board</span>
                  </li>
                  <li>
                    <span>
                      Transparency & Traceability <br /> in Micro Management
                      System
                    </span>
                  </li>
                </ul>
                <div className="text-center pt-3">
                  <button className="btn-request btn btn-secondary">
                    Request a Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Profile2);
