export const addExistPermissionToMenuList = (menuList, selectedRoleObj) => {
  const arr =
    menuList?.map((item) => {
      let menutItem;
      if (item?.menu_items?.length > 0 && selectedRoleObj) {
        menutItem = item?.menu_items?.map((obj) => {
          if (
            selectedRoleObj?.menu_list?.find(
              (val) =>
                val?.value === obj?.value && val?.service === obj?.service
            )
          ) {
            return { ...obj, checked: true };
          } else {
          }
          return { ...obj, checked: false };
        });
        return { ...item, menu_items: menutItem };
      }
      return item;
    }) || [];

  return arr;
};
