import React from "react";
import { useLocation } from "react-router";
import { baseurl } from "../../utils/const";
import AuthLayout from "./AuthLayout";
import ProfileMainLayout from "./ProfileMainLayout";
import UserManagementLayout from "./UserManagementLayout";

const RootLayout = ({ children }) => {
  const location = useLocation();

  if (location.pathname.includes(baseurl + "/user-management")) {
    return <UserManagementLayout>{children}</UserManagementLayout>;
  }
  return (
    <div>
      {[baseurl + "/profile"].includes(location.pathname) ? (
        <ProfileMainLayout>{children}</ProfileMainLayout>
      ) : (
        <AuthLayout>{children}</AuthLayout>
      )}
    </div>
  );
};

export default RootLayout;
