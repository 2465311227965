import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { delMenus, getMenus, setMenus } from "../../services/user.service";

const mapStateToProps = ({ auth }) => ({ auth });

const MenuPage = ({ history }) => {
  const form = useRef();
  const checkBtn = useRef();

  const [, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const [service, setService] = useState("");
  const [menuList, setMenuList] = useState([]);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  React.useEffect(() => {
    if (isLoggedIn) {
    }
  }, [history, isLoggedIn]);
  const fetchMenuItems = () => {
    getMenus()
      .then((res) => {
        // console.log("??", res?.data);
        setMenuList(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  React.useEffect(() => {
    fetchMenuItems();
  }, []);
  const deleteMenuItem = (item) => {
    console.log(item);
    delMenus(item)
      .then((res) => {
        fetchMenuItems();
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!title && !service && !value) {
      alert("please enter required fields");
      return;
    }
    // generateString(10)
    setLoading(true);
    setMenus({ title: title, value: value, service: service })
      .then((res) => {
        fetchMenuItems();
        setTitle("");
        setValue("");
        setService("");
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
    form.current.validateAll();
  };

  return (
    <div className="d-flex align-items-center justify-content-center mt-5">
      <div className="">
        <Form onSubmit={handleSubmit} ref={form}>
          <div className="row">
            <div className="form-group col-md-3">
              <label htmlFor="title">
                Menu <span className="text-danger">*</span>
              </label>
              <div className="position-relative input-wrap">
                <Input
                  type="text"
                  className="form-control"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="value">
                Alias <span className="text-danger">*</span>
              </label>
              <div className="position-relative input-wrap">
                <Input
                  type="text"
                  className="form-control"
                  name="value"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="service">
                Service <span className="text-danger">*</span>
              </label>
              <div className="position-relative input-wrap">
                <Input
                  type="text"
                  className="form-control"
                  name="service"
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-md-2 py-2">
              <button className="btn btn-primary btn-block my-4">Save</button>
            </div>
          </div>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        <Table striped bordered hover className="my-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Alias</th>
              <th>Service</th>
            </tr>
          </thead>
          <tbody>
            {menuList?.map((menu_item, index) =>
              menu_item?.menu_items.map((item) => (
                <tr key={item?.id}>
                  <td>{item?.id}</td>
                  <td>{item?.title}</td>
                  <td>{item?.value}</td>
                  <td>{item?.service}</td>
                  <td style={{ display: "flex" }}>
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => {
                        setTitle(item?.title);
                        setValue(item?.value);
                        setService(item?.service);
                      }}
                    >
                      <span>Edit</span>
                    </button>
                    <button
                      className="btn btn-danger btn-block mx-4"
                      onClick={() => {
                        deleteMenuItem(item);
                      }}
                    >
                      <span>Delete</span>
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(withRouter(MenuPage));
