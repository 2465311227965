import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { profileUpdate } from "../../actions/auth";
const ResetProfileForm = ({ setShowForm, user, dispatch, files }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    dispatch(profileUpdate({ ...data, files }))
      .then(() => {
        setIsLoading(false);
        setShowForm(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (!user?.company?.name) {
      setShowForm(true);
    }
  }, [setShowForm, user]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="fs-18 pt-4 profile-info-form"
    >
      <div className="form-group">
        <label>
          Name <span className="text-danger">*</span>
        </label>
        <input
          id="userName"
          type="text"
          className="form-control brd-shadow"
          placeholder="Enter Name"
          defaultValue={user?.name}
          {...register("username", { required: "Name is required" })}
        />
        {errors.username?.message && (
          <span className="error-text">{errors.username.message}</span>
        )}
      </div>
      {user?.is_admin ? (
        <div className="form-group">
          <label>
            Company Name <span className="text-danger">*</span>
          </label>
          <input
            id="companyName"
            type="text"
            className={`form-control ${
              user?.company?.name ? "brd-shadow" : "brd-shadow-danger"
            }`}
            placeholder="Enter Company Name"
            defaultValue={user?.company?.name}
            {...register("company", { required: "Company Name is required" })}
          />
          {errors.company?.message && (
            <span className="error-text">{errors.company?.message}</span>
          )}
        </div>
      ) : (
        <div className="form-group">
          <label>
            Company Name <span className="text-danger">*</span>
          </label>
          <div className="form-control brd-shadow">{user?.company?.name}</div>
        </div>
      )}
      {/* {user?.is_admin && (
        <div className="form-group">
          <label>
            Business Type <span className="text-danger">*</span>
          </label>
          <Select
            name="company_type"
            value={user?.company?.company_type}
            className="custom-select companyType"
            {...register("company_type", {
              required: "Business Type is required",
            })}
          >
            <option selected>Select I am</option>
            <option value="buyer">Buyer</option>
            <option value="supplier">Supplier</option>
          </Select>
        </div>
      )} */}
      <div className="form-group">
        <label>
          Email address <span className="text-danger">*</span>
        </label>
        <div className="form-control brd-shadow">{user?.email}</div>
      </div>
      <div className="form-group">
        <label>
          Phone number <span className="text-danger">*</span>
        </label>
        <input
          id="phoneNumber"
          type="number"
          className="form-control brd-shadow"
          placeholder="Enter Phone number"
          defaultValue={user?.phone}
          {...register("phone")}
        />
        {/* <span className='error-text'>{errors.phoneNumber?.type === 'required' && "Phone number is requeired"}</span> */}
      </div>
      <div className="buttonWrap pt-3">
        <button
          type="button"
          className="btn btn btn-light float-left btn-discard "
          onClick={() => setShowForm(false)}
          data-dismiss="modal"
        >
          Discard
        </button>
        <button
          type="submit"
          className="btn btn-secondary float-right btn-save"
          onClick={() => setShowForm(true)}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default ResetProfileForm;
