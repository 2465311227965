import axios from "axios";

const API_URL = process.env.REACT_APP_SSO_API_URL;

// console.log(API_URL);

const register = (
  name,
  email,
  password,
  company,
  phone,
  userType,
  userFlag,
  country,
  companyWebsite,
  linkedinProfile,
  designation,
  businessType
) => {
  return axios.post(API_URL + "signup/", {
    name,
    email,
    password,
    company,
    phone,
    user_type: userType,
    user_flag: userFlag,
    country,
    company_website: companyWebsite,
    linkedin_profile: linkedinProfile,
    designation,
    business_type: businessType,
  });
};
const profileUpdate = (name, company, phone, files) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const formData = new FormData();
  formData.append("name", name);
  formData.append("company", company);
  formData.append("phone", phone);
  if (files && files[0] && files[0].name) {
    formData.append("files", files[0], files[0].name);
    formData.append("user_picture", true);
  }
  // console.log(token);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user?.access,
  };
  return axios.put(API_URL + "user/", formData, { headers });
};
const passwordreset = (password) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user?.access,
  };
  return axios.post(
    API_URL + "passwordreset/",
    {
      password,
    },
    { headers: headers }
  );
};

const login = (email, password) => {
  // console.log("API_URL", API_URL);
  return axios
    .post(API_URL + "token/", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.access) {
        localStorage.setItem("user", JSON.stringify(response.data));
        // window.location.replace(
        //   process.env.REACT_APP_OMD_BASE_URL +
        //     "?token=" +
        //     btoa(JSON.stringify(response.data))
        // );
      }

      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  register,
  login,
  logout,
  passwordreset,
  profileUpdate,
};
