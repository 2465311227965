import React from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { logout } from "../actions/auth";
import { baseurl } from "../utils/const";
import { getUser } from "../helpers/utils";

const HomePage = ({ location, dispatch }) => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const search = location.search;
  const type = new URLSearchParams(search).get("type");
  const flag = new URLSearchParams(search).get("flag");

  if (type === "logout") {
    dispatch(logout());
    if (flag === "global") {
      window.location.replace(
        process.env.REACT_APP_GLOBAL_SERVICE_BASE_URL +
          "?type=logout&flag=global"
      );
    }
  }

  if (!isLoggedIn) {
    return <Redirect to={baseurl + "/login"} />;
  }
  const iam = getUser()?.user?.iam;
  if (isLoggedIn && iam == "buyer") {
    return <Redirect to={baseurl + "/profile/update"} />;
  }
  return <Redirect to={baseurl + "/profile"} />;
};

export default connect()(HomePage);
