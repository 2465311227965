import React from "react";
import { Link } from "react-router-dom";
import "./authLayout.styles.css";

const AuthLayout = ({ children }) => {
  return (
    <div className="authLayout">
      <div className="container-fluid h-100">
        <div className="h-100">
          <div className="h-100">{children}</div>
          {/* <div className="col-sm-12 col-md-12 col-lg-4 authLayout-left d-flex align-items-center justify-content-center py-3 text-center">
            <div className="logo-wrap ">
              <div className="logo-inner">
                <a href="https://merchantbay.com">
                  <img
                    className="sm-logo"
                    title="logo"
                    src="/logo_white.png"
                    alt=""
                  />
                </a>
                <h3>
                  Seamless Fashion Sourcing And Product Development Experience
                  <br />
                  Waiting For You.
                </h3>
              </div>
            </div>
          </div> */}
          {/* <div className="col-sm-12 col-md-12 col-lg-8 authLayout-right overflow-auto vh-100">
            <div className="h-100">{children}</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
