import React from "react";
import { connect } from "react-redux";
const mapStateToProps = ({}) => ({});
const Logout = () => {
  React.useEffect(() => {
    localStorage.removeItem("user");
    window.location.replace("/login");
  }, []);
  return <></>;
};

export default connect(mapStateToProps)(Logout);
