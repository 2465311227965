import React from "react";
import { getCountries } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en.json";

const CountrySelect = ({ value, onChange, labels, ...rest }) => {
  return (
    <select
      {...rest}
      value={value}
      onChange={({ target }) => onChange(target.value || undefined)}
    >
      <option value="" disabled>
        Please select a country.
      </option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {en[country]}
        </option>
      ))}
    </select>
  );
};

export default CountrySelect;
