import React from "react";
import { connect } from "react-redux";
import ResetProfileForm from "../form/ResetProfileForm";
import { logout } from "../../actions/auth";
import { withRouter } from "react-router";
import ProfileImageUploadBox from "../input/ProfileImageUploadBox";

const mapStateToProps = ({ auth }) => ({ auth });

const ProfileMainLayout = ({ children, auth, dispatch, history }) => {
  const user = auth?.user?.user;

  const [showForm, setShowForm] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  const handleLogOut = () => {
    dispatch(logout());
  };

  return (
    <div className="sso-profile-wrap container-fluid">
      <div className="row">
        <div className="sso-leftCol col-lg-3 col-md-4 col-sm-12 shadow position-fixed">
          <div className="profile-wrap profileWrap-daskTop d-none d-md-block d-lg-block">
            <div className="profileTop position-relative">
              <ProfileImageUploadBox
                showForm={showForm}
                files={files}
                setFiles={setFiles}
                defaultImage={user?.user_picture}
              />
              {!showForm && (
                <div
                  className="profileEdit position-absolute"
                  data-bs-placement="top"
                  data-bs-toggle="tooltip"
                  data-bs-html="true"
                  title="Edit Profile"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowForm(true)}
                >
                  <button className="btn_edit">
                    <i className="material-icons"> border_color </i>
                  </button>
                </div>
              )}
              {/* {showForm &&
                            <div className="profilePic-change position-absolute" data-bs-toggle="tooltip" data-bs-html="true" title="" data-bs-original-title="Upload Photo">
                                <img src="images/photo.png" alt="" />
                            </div>} */}

              {/* <button
                className="person_man_btn"
                onClick={() => window.location.replace("/user-management")}
              >
                <i className="material-icons">person</i>
              </button> */}
            </div>
            {showForm ? (
              <ResetProfileForm
                setShowForm={setShowForm}
                user={user}
                dispatch={dispatch}
                files={files}
              />
            ) : (
              <div className="fs-18 pt-4 profile-info-form">
                <div className="form-group">
                  <label>Name</label>
                  <div className="form-control brd-shadow">{user?.name}</div>
                </div>
                <div className="form-group">
                  <label>Company Name</label>
                  <div className="form-control brd-shadow">
                    {user?.company?.name}
                  </div>
                </div>
                {/* <div className="form-group">
                  <label>Business Type</label>
                  <div className="form-control brd-shadow">
                    {user?.company?.company_type || ""}
                  </div>
                </div> */}
                <div className="form-group">
                  <label>Email address</label>
                  <div className="form-control brd-shadow">{user?.email}</div>
                </div>
                <div className="form-group">
                  <label>Phone number</label>
                  <div className="form-control brd-shadow">
                    {user?.phone || ""}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-secondary mx-auto d-block btn-save"
                    onClick={() => history.push("/passwordreset")}
                  >
                    Password Reset
                  </button>
                  <button
                    type="submit"
                    className="btn btn-secondary mx-auto d-block btn-save"
                    onClick={handleLogOut}
                  >
                    Logout
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* For Mobile version   */}
          {/* <div className="profile-wrap profileWrap-mobile m-auto d-sm-block d-md-none d-lg-none">
            <div className="profileTop position-relative">
              <div className="profilePic m-auto">
                <img src="images/profile.png" alt="user" />
              </div>
              <div className="text-center pt-3">
                <label>{user?.name}</label>
                <p>{user?.phone}</p>
              </div>
              <div className="profileEdit position-absolute">
                <span data-toggle="modal" data-target="#myModal">
                  <img src="images/edit.png" alt="" />
                </span>
              </div>
            </div>
            <div className="modal" id="myModal">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <span className="close" data-dismiss="modal">
                      &times;
                    </span>
                  </div>
                  <div className="modal-body P-0">
                    <ResetProfileForm
                      setShowForm={setShowForm}
                      user={user}
                      dispatch={dispatch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {children}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(withRouter(ProfileMainLayout));
