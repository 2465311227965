import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import "./registerPage.styles.css";

import { register } from "../../actions/auth";
import { Link } from "react-router-dom";
import CountrySelect from "../../common/input/CountrySelect";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "react-phone-number-input";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vname = (value) => {
  if (value.length < 3 || value.length > 255) {
    return (
      <div className="alert alert-danger" role="alert">
        The name must be between 3 and 255 characters.
      </div>
    );
  }
};

// const validPhone = (value) => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const Register = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("buyer");
  const [businessType, setBusinessType] = useState("brand");
  const [country, setCountry] = useState("BD");
  const [name, setName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userType, setUserType] = useState("buyer");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [privacyAndPolicies, setPrivacyAndPolicies] = useState("");

  const [successful, setSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const onChangeCompany = (e) => {
    const company = e.target.value;
    setCompany(company);
  };
  const onChangeDesignation = (e) => {
    const value = e.target.value;
    setDesignation(value);
  };
  const onChangeBusinessType = (e) => {
    const value = e.target.value;
    setBusinessType(value);
  };
  const onChangeCompanyWebsite = (e) => {
    const value = e.target.value;
    setCompanyWebsite(value);
  };
  const onChangeLinkedinProfile = (e) => {
    const value = e.target.value;
    setLinkedinProfile(value);
  };
  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };
  const onChangeLastName = (e) => {
    const name = e.target.value;
    setlastName(name);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangeUserType = (e) => {
    const userType = e.target.value;
    setUserType(userType);
  };

  const onChangePhone = (e) => {
    setPhone(e);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeConfirmPassword = (e) => {
    const password = e.target.value;
    setConfirmPassword(password);
  };
  const onChangePrivacyAndPolicies = (e) => {
    const privacyAndPolicies = e.target.checked;
    setPrivacyAndPolicies(privacyAndPolicies);
  };

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (successful) {
      // let search = props.location.search;
      const flag = localStorage.getItem("flag");
      // const flag = new URLSearchParams(search).get("flag");
      if (
        flag &&
        (flag === "shop" ||
          flag === "service" ||
          flag === "merchantbay" ||
          flag === "global")
      ) {
        props.history.push("/welcome?flag=" + flag);
      } else {
        props.history.push("/welcome?flag=global");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successful]);

  const handleRegister = (e) => {
    e.preventDefault();
    const flag = localStorage.getItem("flag");

    setSuccessful(false);

    form.current.validateAll();

    if (privacyAndPolicies === "") setPrivacyAndPolicies(false);

    if (
      checkBtn.current.context._errors.length === 0 &&
      password === confirmPassword &&
      isValidPhoneNumber(`+${phone}`) &&
      country !== ""
    ) {
      dispatch(
        register(
          name + " " + lastName,
          email,
          password,
          company,
          phone,
          userType,
          flag,
          country,
          companyWebsite,
          linkedinProfile,
          designation,
          businessType
        )
      )
        .then(() => {
          setSuccessful(true);
        })
        .catch(() => {
          setSuccessful(false);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="signUpPage d-flex align-items-center justify-content-center h-100">
      <div className="signUpForm">
        <div className="mb-35">
          <h3>Sign up to Merchant Bay</h3>
          <h4>
            Already have an account? <Link to="/login">Sign in</Link>
          </h4>
        </div>
        <Form onSubmit={handleRegister} ref={form}>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="companyName">
                Country <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <CountrySelect
                value={country}
                onChange={(val) => {
                  setCountry(val);
                }}
                className="custom-select w-100 userType"
              />
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label className="name">
                Full Name <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="nameField_wrap">
                <div className="position-relative nameField">
                  <Input
                    type="text"
                    className="form-control fs-xl"
                    placeholder="Enter first Name"
                    name="name"
                    value={name}
                    onChange={onChangeName}
                    validations={[required, vname]}
                  />
                </div>
                <div className="position-relative nameField">
                  <Input
                    type="text"
                    className="form-control fs-xl"
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={lastName}
                    onChange={onChangeLastName}
                    validations={[required, vname]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="companyName">
                Company Name <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Your Company Name"
                  name="company"
                  value={company}
                  onChange={onChangeCompany}
                  validations={[required, vname]}
                />
              </div>
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="email">
                Business Email <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Input
                  type="email"
                  className="form-control"
                  placeholder="Enter your email address"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="phoneNumber">
                Phone Number <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <PhoneInput
                pecialLabel=""
                specialLabel=""
                country={country.toLowerCase()}
                value={phone}
                onChange={onChangePhone}
                disableDropdown
                countryCodeEditable={false}
                className="form-control reg-phoneNumber"
              />
              {!isValidPhoneNumber(`+${phone}`) && phone !== "" && (
                <div className="alert alert-danger" role="alert">
                  Phone number is not valid.
                </div>
              )}
            </div>
          </div>

          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="password">
                Password <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Input
                  type="password"
                  className="form-control"
                  placeholder="Your password"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="password">
                Confirm Password <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Input
                  type="password"
                  className="form-control"
                  placeholder="Type your password again"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={onChangeConfirmPassword}
                />
              </div>
              {password !== confirmPassword && (
                <div
                  className={
                    successful ? "alert alert-success" : "alert alert-danger"
                  }
                  role="alert"
                >
                  Password not matching.
                </div>
              )}
            </div>
          </div>

          <div className="form-check">
            <Input
              type="checkbox"
              className="form-check-input"
              name="privacyAndPolicies"
              value={privacyAndPolicies}
              onChange={onChangePrivacyAndPolicies}
              id="check"
            />
            <label className="form-check-label" htmlFor="check">
              I agree to the{" "}
              <a href="https://www.merchantbay.com/policy" target="_blank">
                Terms and Privacy Policies
              </a>
            </label>
          </div>
          {privacyAndPolicies === false && (
            <div
              className={
                successful ? "alert alert-success" : "alert alert-danger"
              }
              role="alert"
            >
              Please agree our privacy and policies.
            </div>
          )}
          <div className="action-wrap">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => setIsLoading(true)}
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Sign up
            </button>
          </div>
          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Register;
