import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { passwordreset } from "../actions/auth";
import { Link } from "react-router-dom";
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const PasswordReset = ({ history }) => {
  const form = useRef();
  const checkBtn = useRef();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isLoggedIn) {
      // history.push("/");
      // return <Redirect to="/" />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();
    console.log(password, confirmPassword);

    if (password === confirmPassword) {
      dispatch(passwordreset(password))
        .then(() => {
          history.push("/");
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      alert("password don't match");
      setLoading(false);
    }
  };

  return (
    <div className="signInPage d-flex align-items-center justify-content-center h-100">
      <div className="signInForm">
        <div className="mb-45">
          <h3 className="mb-3">Reset your password</h3>
          <p>
            Don't want to change password? <Link to="/">Back to profile</Link>
          </p>
        </div>
        <Form onSubmit={handleSubmit} ref={form}>
          <div className="form-group">
            <label htmlFor="password">
              Password <span className="text-danger">*</span>
            </label>
            <div className="position-relative input-wrap">
              <Input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="confirm-password">
              Confirm Password <span className="text-danger">*</span>
            </label>
            <div className="position-relative input-wrap">
              <Input
                type="password"
                className="form-control"
                name="confirm-password"
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
                validations={[required]}
              />
            </div>
          </div>
          <div className="form-group">
            <button className="btn btn-primary btn-block" disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Save</span>
            </button>
          </div>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
    // <div className="col-md-12">
    //   <div className="card card-container">
    //     <img
    //       src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
    //       alt="profile-img"
    //       className="profile-img-card"
    //     />

    //     <Form onSubmit={handleSubmit} ref={form}>

    //       <div className="form-group">
    //         <label htmlFor="password">Password</label>
    //         <Input
    //           type="password"
    //           className="form-control"
    //           name="password"
    //           value={password}
    //           onChange={onChangePassword}
    //           validations={[required]}
    //         />
    //       </div>
    //       <div className="form-group">
    //         <label htmlFor="confirm-password">Confirm Password</label>
    //         <Input
    //           type="password"
    //           className="form-control"
    //           name="confirm-password"
    //           value={confirmPassword}
    //           onChange={onChangeConfirmPassword}
    //           validations={[required]}
    //         />
    //       </div>

    // <div className="form-group">
    //   <button className="btn btn-primary btn-block" disabled={loading}>
    //     {loading && (
    //       <span className="spinner-border spinner-border-sm"></span>
    //     )}
    //     <span>Save</span>
    //   </button>
    // </div>

    // {message && (
    //   <div className="form-group">
    //     <div className="alert alert-danger" role="alert">
    //       {message}
    //     </div>
    //   </div>
    // )}
    // <CheckButton style={{ display: "none" }} ref={checkBtn} />
    //     </Form>
    //   </div>
    // </div>
  );
};

export default PasswordReset;
