import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { connect } from "react-redux";
import { getMenus, getRoles, setRoleMatrix } from "../../services/user.service";
import { addExistPermissionToMenuList } from "../../utils/permissionUtils";

const mapStateToProps = ({ auth }) => ({ auth });
const Permission = ({ history, auth, user }) => {
  const form = useRef();
  const checkBtn = useRef();

  const [, setLoading] = useState(false);

  const [menuList, setMenuList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [finalMenuList, setFinalMenuList] = React.useState([]);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const selectedRoleObj =
    roleList &&
    Array.isArray(roleList) &&
    roleList?.find((item) => item.value === selectedRole);
  //   console.log(selectedRoleObj);
  React.useEffect(() => {
    if (isLoggedIn) {
      fetchItems();
    }
  }, [history, isLoggedIn]);
  const fetchItems = () => {
    getRoles()
      .then((res) => {
        setRoleList(res?.data?.data || []);
        setSelectedRole(res?.data?.data[0].value);
      })
      .catch((e) => {
        console.log(e);
      });
    getMenus()
      .then((res) => {
        setMenuList(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    setFinalMenuList(addExistPermissionToMenuList(menuList, selectedRoleObj));
  }, [menuList, selectedRole, selectedRoleObj]);

  React.useEffect(() => {
    if (user) {
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setRoleMatrix({ menu: finalMenuList, role: selectedRole })
      .then((res) => {
        alert("role permission updated");
      })
      .catch((e) => {
        console.log(e);
      });
    form.current.validateAll();
  };

  return (
    <div className="d-flex align-items-center justify-content-center mt-5">
      <div className="">
        <Form onSubmit={handleSubmit} ref={form}>
          <div className="row">
            <div className="form-group col-md-10">
              <label htmlFor="role">
                Select Team <span className="text-danger">*</span>
              </label>
              <div className="position-relative input-wrap">
                <Select
                  className="form-control"
                  name="role"
                  value={selectedRole}
                  onChange={(e) => {
                    setSelectedRole(e?.target?.value);
                  }}
                >
                  {roleList &&
                    Array.isArray(roleList) &&
                    roleList?.map((item) => (
                      <option key={"role" + item?.id} value={item?.value}>
                        {item?.title}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
            <div className="form-group col-md-2 my-4 py-2">
              <button className="btn btn-primary btn-block">
                <span>Save</span>
              </button>
            </div>
          </div>

          <div className="row form-group my-4">
            {finalMenuList?.map((item, index) => (
              <div
                className="position-relative input-wrap col-md-4 my-4"
                key={"menu" + index}
              >
                <h4>{item?.service}</h4>
                {item?.menu_items?.map((menu_item) => (
                  <div
                    className="checkbox-row"
                    key={"menu_item" + menu_item?.id}
                  >
                    <Input
                      name={menu_item?.value}
                      value={menu_item?.value}
                      type="checkbox"
                      style={{ width: "24px", height: "24px" }}
                      onChange={(e) => {
                        menu_item.checked = e.target.checked;
                      }}
                      checked={!!menu_item?.checked}
                    />
                    <h5>{menu_item?.title}</h5>
                  </div>
                ))}
              </div>
            ))}
          </div>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Permission);
