import React, { useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import Table from "react-bootstrap/Table";
import { getUsers } from "../../services/user.service";
import { withRouter } from "react-router-dom";

const mapStateToProps = ({ auth }) => ({ auth });

const AllUsersPage = ({ history }) => {
  const [loading] = useState(false);
  const [users, setUsers] = useState([]);
  const { isLoggedIn } = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (isLoggedIn) {
      getUsers()
        .then((res) => {
          setUsers(res?.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isLoggedIn]);
  React.useEffect(() => {
    if (isLoggedIn) {
    }
  }, [isLoggedIn]);

  return (
    <div className="management_table_wrap container mt-5">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Role</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users?.map((item, index) => (
            <tr key={index}>
              <td>{item?.id}</td>
              <td>{item?.name}</td>
              <td>{item?.email}</td>
              <td>{item?.phone}</td>
              <td>{item?.user_type}</td>
              <td style={{ display: "flex" }}>
                <button
                  className="btn btn-primary btn-block btn_edit"
                  disabled={loading}
                  onClick={() => {
                    console.log("render");
                    history.push(`/user-management/user/${item.id}`);
                  }}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <i className="material-icons"> border_color</i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default connect(mapStateToProps)(withRouter(AllUsersPage));
