import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/loginPage/Login";
import Register from "./components/registerPage/Register";
// import Profile from "./components/Profile";
import Welcome from "./components/Welcome";

import PasswordReset from "./components/PasswordReset";

import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";
import RootLayout from "./common/layout/RootLayout";
import HomePage from "./components/HomePage";
import Profile2 from "./components/Profile2";
import AllUsersPage from "./pages/userManagement/AllUsersPage";
import UserPage from "./pages/userManagement/UserPage";
import MenuPage from "./pages/userManagement/MenuPage";
import RolePage from "./pages/userManagement/RolePage";
import PermissionPage from "./pages/userManagement/PermissionPage";
import { baseurl } from "./utils/const";
import Logout from "./components/Logout";
import ProfileUserLogin from "./components/ProfileUserLogin";

// import AuthVerify from "./common/AuthVerify";
// import EventBus from "./common/EventBus";

const App = ({ location }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    history.listen(() => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  useEffect(() => {
    const search = location.search;
    const flag = new URLSearchParams(search).get("flag");
    if (flag) {
      localStorage.setItem("flag", flag);
    }
  }, [location.search]);

  // useEffect(() => {
  //   EventBus.on("logout", () => {
  //     logOut();
  //   });

  //   return () => {
  //     EventBus.remove("logout");
  //   };
  // }, [currentUser, logOut]);

  return (
    <>
      {/* <nav className="navbar navbar-expand navbar-dark bg-dark">
            <Link to={"/login"} className="navbar-brand">
              SSO
            </Link>
            <div className="navbar-nav mr-auto"></div>

            {currentUser ? (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/profile"} className="nav-link">
                    {currentUser.username}
                  </Link>
                </li>
                <li className="nav-item">
                  <a href="/" className="nav-link" onClick={logOut}>
                    LogOut
                  </a>
                </li>
              </div>
            ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/login"} className="nav-link">
                    Login
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/register"} className="nav-link">
                    Sign Up
                  </Link>
                </li>
              </div>
            )}
          </nav> */}
      <RootLayout>
        <Switch>
          <Route exact path={baseurl + "/"} component={HomePage} />
          <Route exact path={baseurl + "/login"} component={Login} />
          <Route exact path={baseurl + "/register"} component={Register} />
          <Route exact path={baseurl + "/profile"} component={Profile2} />
          <Route exact path={baseurl + "/welcome"} component={Welcome} />
          <Route exact path={baseurl + "/logout"} component={Logout} />
          <Route exact path="/profile/update" component={ProfileUserLogin} />
          <Route
            exact
            path="/profile/update/:token"
            component={ProfileUserLogin}
          />
          <Route
            exact
            path={baseurl + "/passwordreset"}
            component={PasswordReset}
          />
          {/* <Route exact path="/user-management" component={UserManagement} /> */}
          <Route
            exact
            path={baseurl + "/user-management"}
            component={AllUsersPage}
          />
          <Route
            exact
            path={baseurl + "/user-management/user"}
            component={UserPage}
          />
          <Route
            exact
            path={baseurl + "/user-management/user/:id"}
            component={UserPage}
          />
          <Route
            exact
            path={baseurl + "/user-management/menu"}
            component={MenuPage}
          />
          <Route
            exact
            path={baseurl + "/user-management/role"}
            component={RolePage}
          />
          <Route
            exact
            path={baseurl + "/user-management/permission"}
            component={PermissionPage}
          />
          {/* <Route exact path="/pp2" component={Profile2} /> */}''
        </Switch>
      </RootLayout>
      {/* <AuthVerify logOut={logOut}/> */}
    </>
  );
};

export default withRouter(App);
