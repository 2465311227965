import React, { useState, useRef } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import "./loginPage.styles.css";
import { Link } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Redirect } from "react-router-dom";
import { login } from "../../actions/auth";
import { baseurl } from "../../utils/const";
import { getUser } from "../../helpers/utils";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = ({ history, ...props }) => {
  const form = useRef();
  const checkBtn = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  // const [flag, setFlag] = useState("");

  const dispatch = useDispatch();

  React.useEffect(() => {
    let search = props.location.search;
    const params = new URLSearchParams(search);
    const auth = params.get("token");
    if (auth && auth.split(".") && auth.split(".")[1]) {
      const auth_data = JSON.parse(atob(auth.split(".")[1]));
      const auth_email = auth_data["email"];
      const auth_password = atob(auth_data["password"]);
      if (auth_email && auth_password) {
        loginEvent(auth_email, auth_password);
      }
    } else {
      const iam = getUser()?.user?.iam;
      if (isLoggedIn) {
        if (iam == "buyer") {
          return <Redirect to={baseurl + "/profile/update"} />;
        } else {
          return <Redirect to={baseurl + "/profile"} />;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  function redirectToService() {
    const currentUser = localStorage.getItem("user");
    const flag = localStorage.getItem("flag");
    if (currentUser) {
      const user = JSON.parse(currentUser);
      if (user && user?.access) {
        if (user?.user?.iam == "buyer") {
          history.push(baseurl + "/profile/update");
        } else {
          history.push(baseurl + "/profile");
        }
      }
    }
  }
  const loginEvent = (email, password) => {
    dispatch(login(email, password))
      .then((res) => {
        // props.history.push("/profile");
        redirectToService();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      loginEvent(email, password);
    } else {
      setLoading(false);
    }
  };

  const [showPass, setShowPass] = useState(false);
  const clickHandler = () => {
    setShowPass((prev) => !prev);
  };

  return (
    <div className="signInPage d-flex align-items-center justify-content-center h-100">
      <div className="signInForm">
        <h3 className="mb-45">Sign in to Merchant Bay</h3>
        <Form onSubmit={handleLogin} ref={form}>
          <div className="form-group input-wrap">
            <label className="fs-xl" htmlFor="email">
              Email address <span className="text-danger">*</span>
            </label>
            <div className="position-relative">
              <Input
                type="email"
                name="email"
                value={email}
                onChange={onChangeEmail}
                validations={[required]}
                className="form-control fs-xl"
                placeholder="Enter your Email address"
              />
            </div>
          </div>

          <div className="form-group input-wrap">
            <label className="fs-xl" htmlFor="password">
              Password <span className="text-danger">*</span>
            </label>
            <div className="position-relative">
              <input
                type="password"
                className="form-control fs-xl"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
                placeholder="Your password"
              />
            </div>
          </div>
          <div className="forgot_pass_box">
            <div className="remem-box">
              <input type="checkbox" name="remember" />
              <span>Remember Me</span>
            </div>
            <div class="forgot-pass">
              {/* <Link to="/">Forgot Password?</Link> */}
            </div>
          </div>

          <div className="action-wrap mb-20">
            <button
              type="submit"
              className="btn btn-primary d-inline-flex align-items-center justify-content-center h-49 fs-xl"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Sign In
            </button>
          </div>
          <h5 className="signup text-center">
            Don't have an account?<Link to="/register">Sign Up</Link>
          </h5>
          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default connect()(Login);
