import React from "react";
import { useDropzone } from "react-dropzone";

const UserPicUpdate = ({
  files = [],
  setFiles,
  maxFile = 1,
  defaultImage,
  showForm,
  ...props
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFile,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        setFiles(
          fileRejections
            .filter((item, index) => index < maxFile)
            .map((item) =>
              Object.assign(item.file, {
                preview: URL.createObjectURL(item.file),
              })
            )
        );
      } else {
        setFiles(
          acceptedFiles.map((item) =>
            Object.assign(item, {
              preview: URL.createObjectURL(item),
            })
          )
        );
      }
    },
  });

  return (
    <div className="profilePic m-auto">
      <div
        className="profilePic-inner"
        style={{ height: "120px", width: "120px" }}
      >
        {files.length > 0 ? (
          <img src={files[0]?.preview} alt="user" />
        ) : (
          <img src={defaultImage || "images/profile.png"} alt="user" />
        )}
      </div>
      {showForm && (
        <div
          className="imageUpload"
          {...props}
          {...getRootProps()}
          style={{
            bottom: "29px",
            right: "32px",
            border: "none",
          }}
        >
          <input {...getInputProps()} />
          <span className="material-icons">camera_alt</span>
        </div>
      )}
    </div>
  );
};

export default UserPicUpdate;
