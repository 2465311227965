import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { MdModeEditOutline } from "react-icons/md";

// import "./registerPage/registerPage.style.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { login, register } from "../actions/auth";
import { Link } from "react-router-dom";
import CountrySelect from "../common/input/CountrySelect";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import { getUser } from "../services/user.service";
import ProfileImageUploadBox from "../common/input/ProfileImageUploadBox";
import UserPicUpdate from "./UserPicUpdate";
import { baseurl } from "../utils/const";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
const queryString = require("query-string");
const API_URL = process.env.REACT_APP_SSO_API_URL;
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vname = (value) => {
  if (value.length < 3 || value.length > 255) {
    return (
      <div className="alert alert-danger" role="alert">
        The name must be between 3 and 255 characters.
      </div>
    );
  }
};

// const validPhone = (value) => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const ProfileUserLogin = ({ history, match, ...props }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const form = useRef();
  const checkBtn = useRef();
  const [showForm, setShowForm] = React.useState(false);
  const [companyId, setCompanyId] = useState(0);
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [country, setCountry] = useState("BD");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userType, setUserType] = useState("buyer");
  const [userFlag, setUserFlag] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [privacyAndPolicies, setPrivacyAndPolicies] = useState("");
  const [files, setFiles] = React.useState([]);
  const [successful, setSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [flag, setFlag] = useState("");
  const [defaultImage, setDefaultImage] = useState(
    "https://omd.sgp1.cdn.digitaloceanspaces.com/user.png"
  );
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  React.useEffect(() => {
    if (files.length > 0) {
      setIsEdited(true);
    }
  }, [files]);
  React.useEffect(() => {
    // check token

    const token = match?.params?.token;
    if (token) {
      if (token.split(".")[1]) {
        setIsLoading(true);
        const user = JSON.parse(atob(token.split(".")[1]));
        const token_email = user?.email;
        const token_password = atob(user?.password);
        dispatch(login(token_email, token_password))
          .then((res) => {
            setIsLoading(false);
            // window.location.replace(baseurl + "/profile/update");
            const search = props.location.search; // could be '?foo=bar'
            const params = new URLSearchParams(search);
            const from = params.get("from"); // bar
            setFlag(from);
            // props.history.push(baseurl + "/profile/update");
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    } else {
      const user = JSON.parse(localStorage.getItem("user"))?.user;
      if (!user) {
        window.location.replace(baseurl + "/login");
      }
    }
  }, [history, match]);
  React.useEffect(() => {
    if (!isLoading) {
      const user = JSON.parse(localStorage.getItem("user"))?.user;
      if (user) {
        getUser(user?.id).then((res) => {
          const user_data = res?.data;

          console.log(user_data);
          const password = atob(user?.password);
          const company = user?.company;
          setName(user_data["name"]);
          setPassword(password);
          setConfirmPassword(password);
          setBusinessType(user_data["business_type"]);
          setCompany(company?.name);
          setDesignation(user_data["designation"] || "");
          setEmail(user_data["email"]);
          setPhone(user_data["phone"]);
          setCompanyWebsite(user_data["company_website"] || "");
          setLinkedinProfile(user_data["linkedin_profile"] || "");
          setUserFlag(user_data["user_flag"]);
          if (user_data["user_picture"]) {
            setDefaultImage(user_data["user_picture"]);
          }
          setFiles([]);
          setCountry(user_data["country"] || "BD");
        });
      }
    }
  }, [isLoading]);
  const onChangeCompany = (e) => {
    const company = e.target.value;
    setCompany(company);
    setIsEdited(true);
  };
  const onChangeDesignation = (e) => {
    const value = e.target.value;
    setDesignation(value);
    setIsEdited(true);
  };
  const onChangeBusinessType = (e) => {
    const value = e.target.value;
    setBusinessType(value);
    setIsEdited(true);
  };
  const onChangeCompanyWebsite = (e) => {
    const value = e.target.value;
    setCompanyWebsite(value);
    setIsEdited(true);
  };
  const onChangeLinkedinProfile = (e) => {
    const value = e.target.value;
    setLinkedinProfile(value);
    setIsEdited(true);
  };
  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
    setIsEdited(true);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangeUserType = (e) => {
    const userType = e.target.value;
    setUserType(userType);
  };

  const onChangePhone = (e) => {
    setPhone(e);
    setIsEdited(true);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    setIsEdited(true);
  };

  const onChangeConfirmPassword = (e) => {
    const password = e.target.value;
    setConfirmPassword(password);
    setIsEdited(true);
  };
  const profileUpdate = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const formData = new FormData();
    formData.append("name", name);
    formData.append("company", company);
    formData.append("password", password);
    formData.append("phone", phone);
    // formData.append("user_type", userType);
    // formData.append("user_flag", userFlag);
    formData.append("country", country);
    formData.append("company_website", companyWebsite);
    formData.append("linkedin_profile", linkedinProfile);
    formData.append("designation", designation);
    formData.append("business_type", businessType);

    if (files && files[0] && files[0].name) {
      formData.append("files", files[0], files[0].name);
      formData.append("user_picture", true);
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user?.access,
    };
    setIsLoading(true);
    axios.put(API_URL + "user/", formData, { headers }).then((res) => {
      handleShow();
      dispatch(login(email, password))
        .then((res) => {
          // props.history.push("/profile");
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const flag = localStorage.getItem("flag");

    setSuccessful(false);

    form.current.validateAll();

    if (privacyAndPolicies === "") setPrivacyAndPolicies(false);

    if (
      password === confirmPassword &&
      isValidPhoneNumber(`+${phone}`) &&
      country !== ""
    ) {
      profileUpdate();
    } else {
      alert("error");
    }
  };

  return (
    <div className="signUpPage d-flex align-items-center justify-content-center h-100">
      <div className="signUpForm">
        <div
          className="signUpPrifileUpdate"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            {" "}
            <a href="https://app.merchantbay.com/my-panel/user/profile">
              <button
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#97e6974f",
                  color: "green",
                  border: "1px solid #97e6974f",
                  padding: "2px 10px",
                }}
              >
                Back My Panel
              </button>
            </a>
          </div>
          <>{/* <UserPicEdit /> */}</>
          <div className="sso-leftCol">
            <div className="profileTop position-relative">
              <UserPicUpdate
                showForm={true}
                files={files}
                setFiles={setFiles}
                defaultImage={defaultImage}
              />
            </div>
          </div>

          {/* <div>
            <div className="upload">
              <img src="" width={100} height={100} alt="" />
              <div className="round">
                <input type="file" />
                <AiOutlineCamera />
              </div>
            </div>
          </div> */}

          <div>
            <MdModeEditOutline
              style={{
                color: "white",
                backgroundColor: "green",
                borderRadius: "5px",
                height: "25px",
                width: "26px",
              }}
              onClick={() => {
                setIsEdit(!isEdit);
              }}
            />
          </div>

          {/* <h3>Sign up to Merchant Bay</h3> */}
          {/* <h4>
            Already have an account? <Link to="/login">Sign in</Link>{" "}
          </h4> */}
        </div>
        <Form ref={form}>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="companyName">
                Country <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <CountrySelect
                value={country}
                onChange={(val) => {
                  setCountry(val);
                  setIsEdited(true);
                }}
                className="custom-select w-100 userType"
                disabled={!isEdit}
              />
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="companyName">
                I am<span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Select
                  name="userType"
                  value={userType}
                  onChange={onChangeUserType}
                  className="custom-select userType w-100"
                  validations={[required]}
                  disabled
                >
                  <option selected>Select I am</option>
                  <option value="buyer">Buyer</option>
                  <option value="supplier">Supplier</option>
                  <option value="designer">Designer</option>
                </Select>
              </div>
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label className="name">
                Name <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Input
                  type="text"
                  className="form-control fs-xl"
                  placeholder="Enter your Name"
                  name="name"
                  value={name}
                  onChange={onChangeName}
                  validations={[required, vname]}
                  readOnly={!isEdit}
                />
              </div>
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="companyName">Business Type</label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Select
                  name="businessType"
                  value={businessType}
                  onChange={onChangeBusinessType}
                  className="custom-select userType w-100"
                  disabled={!isEdit}
                >
                  <option value="" selected disabled>
                    Business Type
                  </option>
                  {userType === "supplier" || userType === "designer" ? (
                    <>
                      <option value="manufacturer">Manufacturer</option>
                      <option value="design_studio">Design Studio</option>
                      <option value="wholesaler">Wholesaler</option>
                    </>
                  ) : (
                    <>
                      <option value="brand">Brand</option>
                      <option value="retailShop">Retail Shop</option>
                      <option value="tradingCompany">Trading Company</option>
                      {/* <option value="supplier">Supplier</option> */}
                    </>
                  )}
                </Select>
              </div>
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="companyName">
                Company Name <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Your Company Name"
                  name="company"
                  value={company}
                  onChange={onChangeCompany}
                  validations={[required, vname]}
                  readOnly={!isEdit}
                />
              </div>
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="companyName">Designation</label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Your Designation"
                  name="designation"
                  value={designation}
                  onChange={onChangeDesignation}
                  readOnly={!isEdit}
                />
              </div>
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="email">
                Email Address <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Input
                  type="email"
                  className="form-control"
                  placeholder="Enter your email address"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="phoneNumber">
                Phone Number <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <PhoneInput
                pecialLabel=""
                specialLabel=""
                country={country.toLowerCase()}
                value={phone}
                onChange={onChangePhone}
                disableDropdown
                countryCodeEditable={false}
                className="form-control reg-phoneNumber"
                disabled={!isEdit}
              />
              {!isValidPhoneNumber(`+${phone}`) && phone !== "" && (
                <div className="alert alert-danger" role="alert">
                  Phone number is not valid.
                </div>
              )}
            </div>
          </div>

          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="password">
                Password <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Input
                  type="password"
                  className="form-control"
                  placeholder="Your password"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                  readOnly={!isEdit}
                />
              </div>
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="password">
                Confirm Password <span class="text-danger">*</span>
              </label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Input
                  type="password"
                  className="form-control"
                  placeholder="Type your password again"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={onChangeConfirmPassword}
                  readOnly={!isEdit}
                />
              </div>
              {password !== confirmPassword && (
                <div
                  className={
                    successful ? "alert alert-success" : "alert alert-danger"
                  }
                  role="alert"
                >
                  Password not matching.
                </div>
              )}
            </div>
          </div>

          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="companyName">Company Website</label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Company Website"
                  name="company_website"
                  value={companyWebsite}
                  onChange={onChangeCompanyWebsite}
                  readOnly={!isEdit}
                />
              </div>
            </div>
          </div>
          <div className="row form-group input-wrap">
            <div className="col-sm-12 col-md-3">
              <label htmlFor="companyName">LinkedIn Profile</label>
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="position-relative">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="LinkedIn Profile"
                  name="linkedin_profile"
                  value={linkedinProfile}
                  onChange={onChangeLinkedinProfile}
                  readOnly={!isEdit}
                />
              </div>
            </div>
          </div>
        </Form>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            style={{
              padding: "6px 95px",
              border: "1px solid green",
              borderRadius: "5px",
              color: "green",
              fontWeight: "500",
              backgroundColor: "white",
            }}
            onClick={() => {
              setIsLoading(false);
              setTimeout(() => {
                setIsEdited(false);
                setIsLoading(true);
              }, 100);
            }}
          >
            DISCARD
          </button>
          <button
            style={
              isEdited
                ? {
                  padding: "6px 60px",
                  border: "1px solid green",
                  borderRadius: "5px",
                  color: "white",
                  fontWeight: "500",
                  backgroundColor: "green",
                }
                : {
                  padding: "6px 60px",
                  border: "1px solid gray",
                  borderRadius: "5px",
                  color: "white",
                  fontWeight: "500",
                  backgroundColor: "gray",
                }
            }
            onClick={(e) => {
              handleUpdate(e);
            }}
            disabled={!isEdited}
          >
            SAVE CHANGES
          </button>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          style={{ border: "none", margin: "-0.5rem -2.7rem -0.5rem auto" }}
          closeButton
        >
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>

        <Modal.Body style={{ border: "none", textAlign: "center" }}>
          Changes Have Been Saved Please Login Again
        </Modal.Body>
        <Modal.Footer style={{ margin: "auto", border: "none" }}>
          <Button
            variant="primary"
            onClick={() => {
              handleClose();
              if (flag == "serviceprofile") {
                window.location.replace(
                  "https://merchantbay.com/?type=logout&flag=global&from=serviceprofile"
                );
              } else {
                window.location.replace("https://merchantbay.com/login");
              }
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProfileUserLogin;
