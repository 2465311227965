import axios from "axios";

export const API_URL = process.env.REACT_APP_SSO_API_URL;

// console.log(API_URL);
export const headers = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user?.access,
  };
};
export const inviteUser = (data) => {
  return axios.post(API_URL + "signup/", data, { headers: headers() });
};
export const updateUser = (data) => {
  return axios.put(API_URL + "profile/update/", data, { headers: headers() });
};
export const getUsers = () => {
  return axios.get(API_URL + "users", { headers: headers() });
};
export const getUser = (id) => {
  return axios.get(API_URL + `user/${id}`, { headers: headers() });
};
export const setMenus = (data) => {
  return axios.post(API_URL + "menus", { data }, { headers: headers() });
};
export const setRoles = (data) => {
  return axios.post(API_URL + "roles", { data }, { headers: headers() });
};
export const getMenus = () => {
  return axios.get(API_URL + "menus", { headers: headers() });
};
export const getRoles = () => {
  return axios.get(API_URL + "roles", { headers: headers() });
};
export const delMenus = (data) => {
  return axios.delete(API_URL + "menus/" + data["id"], { headers: headers() });
};
export const delRoles = (data) => {
  return axios.delete(API_URL + "roles/" + data["id"], { headers: headers() });
};
export const setRoleMatrix = (data) => {
  return axios.post(API_URL + "rolematrix", { data }, { headers: headers() });
};
export const getRoleMatrix = (data) => {
  return axios.get(API_URL + "rolematrix", { data }, { headers: headers() });
};
export const setPermissionMatrix = (data) => {
  return axios.post(
    API_URL + "permissionmatrix",
    { data },
    { headers: headers() }
  );
};
export const getPermissionMatrix = (data) => {
  return axios.get(
    API_URL + "permissionmatrix",
    { data },
    { headers: headers() }
  );
};
